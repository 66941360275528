import { HeadFC } from 'gatsby';
import React, { Suspense, lazy } from 'react';

import { Seo } from '../../components';
import ErrorBoundary from '../../components/ErrorBoundary';
import Loader from '../../components/loading';
import { NavProvider } from '../../context/nav';

const LegalInformation = lazy(
  () => import('../../components/sections/LegalInformation'),
);

const info = [
  {
    text: 'En cumplimiento de lo dispuesto en la Ley 1581 de 2012 y sus decretos reglamentarios, Apps Makers SAS (marca comercial Draxo) y que en adelante estará identificada como Apps Makers SAS, establece su Política de Privacidad e Integrada de Tratamiento de Datos Personales, con el fin de garantizar la protección de los datos personales de los titulares y el cumplimiento de la normativa colombiana en materia de protección de datos personales.',
  },
  {
    title: '1. Responsable del tratamiento de los datos personales:',
    text: 'Apps Makers SAS, identificada con NIT 901.025.826-3 y localizada en Medellín, Colombia, es el responsable del tratamiento de los datos personales que recolecta, almacena, usa, comparte y protege en el desarrollo de sus actividades comerciales y de prestación de servicios.',
  },
  {
    title: '2. Datos personales recolectados y finalidades del tratamiento:',
    text: `Apps Makers SAS recolecta y trata los siguientes datos personales de los titulares: [insertar los datos personales que se recolectan, por ejemplo: nombres, apellidos, identificación, dirección, correo electrónico, número de teléfono, datos de facturación, entre otros].
    <br />
    <br />
    Los datos personales recolectados por Apps Makers SAS son utilizados con las siguientes finalidades:
    <br />
    <br />
    1. Gestión de clientes y proveedores: para la gestión comercial y administrativa de clientes, proveedores y contratistas.
    <br />
    <br />
    2. Prestación de servicios: para la prestación de servicios y/o productos ofrecidos por Apps Makers SAS.
    <br />
    <br />
    3. Marketing: para el envío de comunicaciones comerciales y promocionales sobre nuestros productos y servicios.
    <br />
    <br />
    4. Cumplimiento de obligaciones legales: para el cumplimiento de las obligaciones legales a cargo de Apps Makers SAS.`,
  },
  {
    title: '3. Procedimientos y medidas de seguridad:',
    text: 'Apps Makers SAS implementa los procedimientos y medidas de seguridad necesarios para garantizar la protección de los datos personales recolectados y evitar su adulteración, pérdida, consulta, uso o acceso no autorizado. Estas medidas incluyen el uso de tecnologías de encriptación, controles de acceso y monitoreo de los sistemas de información.',
  },
  {
    title: '4. Compartición de datos personales:',
    text: 'Apps Makers SAS podrá compartir los datos personales recolectados con terceros para el cumplimiento de las finalidades mencionadas en esta política de privacidad e integrada de tratamiento de datos personales. En estos casos, Apps Makers SAS suscribirá acuerdos de confidencialidad y de protección de datos personales con los terceros involucrados.',
  },
  {
    title: '5. Derechos de los titulares:',
    text: `Los titulares de los datos personales recolectados por Apps Makers SAS tienen derecho a:
    <br />
    <br />
    1. Acceder a sus datos personales.
    <br />
    <br />
    2. Conocer, actualizar y rectificar sus datos personales.
    <br />
    <br />
    3. Oponerse al tratamiento de sus datos personales.
    <br />
    <br />
    4. Solicitar la portabilidad de sus datos personales.
    <br />
    <br />
    Para ejercer estos derechos, el titular deberá presentar una solicitud a través de los canales habilitados por Apps Makers SAS.`,
  },
  {
    title: '6. Capacitación al personal:',
    text: 'Apps Makers SAS capacita a su personal para el adecuado manejo de los datos personales y para el cumplimiento de la presente política de privacidad e integrada de tratamiento de datos personales.',
  },
  {
    title: '7. Transferencias internacionales de datos personales:',
    text: 'En caso de ser necesario, Apps Makers SAS podrá realizar transferencias internacionales de datos personales a terceros países u organizaciones internacionales que ofrezcan un nivel adecuado de protección de datos personales, o en caso contrario, se adoptarán las medidas necesarias para garantizar la protección de los datos personales.',
  },
  {
    title:
      '8. Vigencia y actualización de la política de privacidad e integrada de tratamiento de datos personales:',
    text: 'La presente política de privacidad e integrada de tratamiento de datos personales tiene vigencia a partir de la fecha de su publicación y se actualizará de manera periódica para garantizar su adecuación a los cambios normativos y a las necesidades de Apps Makers SAS y de los titulares de los datos personales.',
  },
  {
    title:
      '9. Aceptación de la política de privacidad e integrada de tratamiento de datos personales: ',
    text: 'Al aceptar la presente política de privacidad e integrada de tratamiento de datos personales, el titular de los datos personales reconoce que ha leído, entendido y aceptado sus términos y condiciones. Si el titular no está de acuerdo con esta política, deberá abstenerse de utilizar los servicios de Apps Makers SAS.',
  },
  {
    title: '10. Contacto: ',
    text: `Al personales podrá comunicarse con Apps Makers SAS a través de los canales habilitados para tal fin.
    <br />
    <br />
    Esta Política de Privacidad e Integrada de Tratamiento de Datos Personales ha sido elaborada y aprobada por Apps Makers SAS, y su última actualización se realizó el 1ro de mayo de 2023.`,
  },
];

export default function PrivacyPolicy() {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return null;
  return (
    <NavProvider>
      <ErrorBoundary>
        <Suspense fallback={Loader()}>
          <LegalInformation
            info={info}
            topTitle="Privacy policy"
            lastUpdate="01/10/2022"
          />
        </Suspense>
      </ErrorBoundary>
    </NavProvider>
  );
}

export const Head: HeadFC = () => <Seo />;
